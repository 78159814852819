import React from "react"

export const Logo = () => (
  <svg viewBox="0 0 1 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.638125 0.8125H0.3125V0.75H0.638125V0.8125ZM0.875 0.4375V0.875C0.875 0.909375 0.846875 0.9375 0.8125 0.9375H0.1875C0.153125 0.9375 0.125 0.909375 0.125 0.875V0.4375C0.125 0.403125 0.153125 0.375 0.1875 0.375H0.25V0.25C0.25 0.1125 0.3625 0 0.5 0C0.6375 0 0.75 0.1125 0.75 0.25V0.375H0.8125C0.846875 0.375 0.875 0.403125 0.875 0.4375ZM0.3625 0.375H0.638125V0.25C0.638125 0.17375 0.576875 0.1125 0.500625 0.1125C0.424375 0.1125 0.363125 0.17375 0.363125 0.25L0.3625 0.375ZM0.8125 0.4375H0.25V0.875H0.8125V0.4375ZM0.75 0.5H0.3125V0.5625H0.75V0.5ZM0.500625 0.625H0.3125V0.6875H0.500625V0.625Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="0.125"
        y1="0.714844"
        x2="0.875"
        y2="0.566406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#99F6E4" />
        <stop offset="1" stop-color="#22D3EE" />
      </linearGradient>
    </defs>
  </svg>
)
